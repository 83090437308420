import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { OperacaoResponse, OperacaoRequest } from 'src/app/services/operacoes/operacao/operacao.service';
import { FormUtilService, fadeAnimation } from 'src/app/services/util/form-util.service';
import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Terminal } from 'src/app/services/tabelas/terminais/terminais.service';
import { Cliente } from 'src/app/services/tabelas/clientes/clientes.service';
import { Planta } from 'src/app/services/tabelas/plantas/plantas.service';
import { Berco } from 'src/app/services/tabelas/bercos/bercos.service';
import { Navio } from 'src/app/services/tabelas/navios/navios.service';
import { startWith, filter, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import moment from "moment";
import { AlertTemplateComponent } from '../alert-template/alert-template.component';
import { CountryService } from 'src/app/services/util/country-service.service';

@Component({
  selector: 'app-edit-operacao',
  templateUrl: './edit-operacao.component.html',
  styleUrls: ['./edit-operacao.component.scss'],
  animations: [fadeAnimation]

})
export class EditOperacaoComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('table', { static: true }) table: MatTable<EditOperacaoComponent>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('changeFocus', { static: true }) changeFocus: ElementRef;
  public operacao: any;
  public operacoesService: any;
  public naviosService: any;
  public clienteService: any;
  public plantasService: any;
  public terminaisService: any;
  public bercosService: any;
  public title: string;
  public operacaoRequest: any;
  public loader: any;
  public isTotal: boolean;
  public operacaoEventoService: any;
  public operacaoVazaoService: any;
  public evento: any;
  public proximoEvento: any;
  public alert: any;
  public tipo: any;
  public dado: any;
  public id_operacao:number = 0;
  public id_lote: number;
  public novoItem: any = {
    hora: "",
    volumeM3: 0,
    pressao: 0,
    pressaoManifold: 0,
    destinoVolumeM3: 0,
    destinoPressao: 0,
  };
  inputDataObj = {
    hora: '',
    volumeM3: '',
    pressao: '',
    pressaoManifold: '',
    destinoVolumeM3: '',
    destinoPressao: '',
    descricao: ' '
  }

  public terminais = new Array<Terminal>();
  public filteredTerminais: Observable<Array<Terminal>>;
  public bercos = new Array<Berco>();
  public filteredBercos: Observable<Array<Berco>>;
  public navios = new Array<Navio>();
  public filteredNaviosOrigem: Observable<Array<Navio>>;
  public clientes = new Array<Cliente>();
  public filteredCliente: Observable<Array<Cliente>>;

  public filteredNaviosDestino: Observable<Array<Navio>>;
  public plantas = new Array<Planta>();
  public filteredPlantas: Observable<Array<Planta>>;

  public allColumns: string[] = ["HORA", "VOLUME", "PRESSAO", "PRESSAO_MANIFOLD", "VOLUME_DESTINO", "PRESSAO_DESTINO","ACAO"];
  // public listaLabels: string[] = ["HORA", "VOLUME", "PRESSAO", "PRESSAO_MANIFOLD", "VOLUME_DESTINO", "PRESSAO_DESTINO", "ACAO"];
  public dataSource: MatTableDataSource<EditOperacaoComponent>;

  public editOperacaoForm: FormGroup;
  public novaOperacaoForm: FormGroup;
  public validationsForm: FormGroup;
  public operacoes: OperacaoResponse[];
  public tiposArquivo: any[] = [];
  public tiposAtracao: any[] = [];
  public tiposPosicao: any[] = [];
  public formUtils: FormUtilService;
  public quantClick: number = 0;
  public quantLotes: number = 0;

  public newDate: any;
  public hora: any;
  public descricao: any;

  public type: any;
  public editableId: boolean = false;


  public validationMessages = {
    requiredText: [
      { type: 'required', message: 'This field is required.' }
    ],
    breveText: [
      { type: 'required', message: 'This field is required.' },
      { type: 'already_Exists', message: 'This field value already exists.' }
    ],
    paisDestino: [
      { type: 'required', message: 'This field is required.' },
      { type: 'invalid_country', message: 'País invalido.' }
    ],
  };

  private strippingTimeNow = {
    begin: '',
    end: ''
  }

  public stripping = {
    begin: '',
    end: ''
  }

  public filterdCountrys = [];
  public paisSigla = '';

  constructor(
    public dialogRef: MatDialogRef<EditOperacaoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public countryService: CountryService
  ) {

    this.operacao = data.operacao;
    this.operacoesService = data.operacoesService;
    this.plantasService = data.plantasService;

    this.naviosService = data.naviosService;
    this.terminaisService = data.terminaisService;
    this.bercosService = data.bercosService;
    this.clienteService = data.clienteService;
    this.title = data.title;
    this.operacaoRequest = data.operacaoRequest;
    this.loader = data.loader;
    this.isTotal = data.isTotal;
    this.operacaoEventoService = data.operacaoEventoService;
    this.operacaoVazaoService = data.operacaoVazaoService;
    this.alert = data.alert;
    this.tipo = data.tipo
    this.evento = data.evento;
    this.proximoEvento = data.proximoEvento;
    this.type = data.type;
    this.id_lote = data.id_lote;

    // console.log(data);
    try {
      this.id_operacao = data.id_operacao;
      this.strippingTimeNow.begin = data?.evento? this.getStrippingTime(data?.evento[0].strippingInicio):'';
      this.strippingTimeNow.end = data?.evento?this.getStrippingTime(data?.evento[0].strippingFim):'';
      this.stripping = {...this.strippingTimeNow};
    }catch(err){
      console.log(err);
    }
    
    if (!this.isTotal && this.type === "ponto-controle") {
      this.newDate = this.evento.dataFim;
      this.newDate = this.newDate.replace('-', '').replace('-', '')
      let ano = this.newDate.substring(0, 4)
      let mes = this.newDate.substring(4, 6)
      let dia = this.newDate.substring(6, 8)
      this.newDate = `${dia}/${mes}/${ano}`
    }
    else if (!this.isTotal && this.type === "sub-itens") {
      this.newDate = this.evento.data;
      this.newDate = this.newDate.replace('-', '').replace('-', '')
      let ano = this.newDate.substring(0, 4)
      let mes = this.newDate.substring(4, 6)
      let dia = this.newDate.substring(6, 8)
      this.newDate = `${dia}/${mes}/${ano}`
    }
    else if (!this.isTotal && this.type === "edit-vazao") {
      this.dataSource = data.evento
      //console.log(data.evento)
    }

  }

  ionViewDidEnter() {
  }

  async ngOnInit() {
    if (this.isTotal) {
      this.novaOperacaoForm = this.formBuilder.group({})
      this.editOperacaoForm = this.formBuilder.group({
        id_operacao: new FormControl(''),
        nomeCliente: new FormControl({ value: "", disabled: true }, Validators.required),
        id_cliente: new FormControl(''),
        numero: new FormControl({ value: "", disabled: true }, Validators.required),
        terminalBreve: new FormControl({ value: "", disabled: true }, Validators.required),
        id_terminal: new FormControl(''),
        bercoBreve: new FormControl(''),
        id_berco: new FormControl(''),
        navioOrigemBreve: new FormControl({ value: "", disabled: true }, Validators.required),
        id_navioOrigem: new FormControl(''),
        navioDestinoBreve: new FormControl({ value: "", disabled: true }, Validators.required),
        id_navioDestino: new FormControl(''),
        plantaBreve: new FormControl({ value: "", disabled: true }, Validators.required),
        id_planta: new FormControl(''),
        caladoEntrada: new FormControl(''),
        caladoEntradaDestino: new FormControl(''),

        viagemOrigem: new FormControl(''),
        viagemDestino: new FormControl(''),

        escalaOrigem: new FormControl(''),
        escalaDestino: new FormControl(''),

        id_posicaoBerco: new FormControl(''),
        id_posicaoBercoDestino: new FormControl(''),

        id_bordoAtracacao: new FormControl(''),
        id_bordoAtracacaoDestino: new FormControl(''),
        id_loteA: new FormControl(''),
        id_loteB: new FormControl(''),
        id_loteC: new FormControl(''),
        id_loteD: new FormControl(''),

        numeroLoteA: new FormControl({ value: "", disabled: true }, Validators.required),
        numeroLoteB: new FormControl({ value: "", disabled: true }, Validators.required),
        numeroLoteC: new FormControl({ value: "", disabled: true }, Validators.required),
        numeroLoteD: new FormControl({ value: "", disabled: true }, Validators.required),
        procedenciaLoteA: new FormControl(''),
        procedenciaLoteB: new FormControl(''),
        procedenciaLoteC: new FormControl(''),
        procedenciaLoteD: new FormControl(''),
        NFloteA: new FormControl(''),
        NFloteB: new FormControl(''),
        NFloteC: new FormControl(''),
        NFloteD: new FormControl(''),
        OSGloteA: new FormControl(''),
        OSGloteB: new FormControl(''),
        OSGloteC: new FormControl(''),
        OSGloteD: new FormControl(''),
        volumeM3A: new FormControl({ value: "", disabled: true }, Validators.required),
        volumeM3B: new FormControl({ value: "", disabled: true }, Validators.required),
        volumeM3C: new FormControl({ value: "", disabled: true }, Validators.required),
        volumeM3D: new FormControl({ value: "", disabled: true }, Validators.required),

        paisDestino: new FormControl('', Validators.required),
      });
      this.formUtils = new FormUtilService(this.novaOperacaoForm, this.editOperacaoForm)


      await this.naviosService.getNavios().then(navios => {
        this.navios = navios;
        this.filteredNaviosOrigem = this.editOperacaoForm.controls.navioOrigemBreve.valueChanges
          .pipe(
            startWith(''),
            filter(value => value !== null),
            map(value => {
              if (value.descricao) {
                return this.navios.filter(c => c.descricao.toLowerCase().includes(value.descricao.toLowerCase()));
              } else {
                return this.navios.filter(c => c.descricao.toLowerCase().includes(value.toLowerCase()));
              }
            })
          );
        this.filteredNaviosDestino = this.editOperacaoForm.controls.navioDestinoBreve.valueChanges
          .pipe(
            startWith(''),
            filter(value => value !== null),
            map(value => {
              if (value.descricao) {
                return this.navios.filter(c => c.descricao.toLowerCase().includes(value.descricao.toLowerCase()));
              } else {
                return this.navios.filter(c => c.descricao.toLowerCase().includes(value.toLowerCase()));
              }
            })
          );
      });
      await this.clienteService.getClientes().then(clientes => {
        //console.log(clientes)

        this.clientes = clientes;
        this.filteredCliente = this.editOperacaoForm.controls.nomeCliente.valueChanges
          .pipe(
            startWith(''),
            filter(value => value !== null),
            map(value => {
              if (value.nome) {
                return this.clientes.filter(c => c.nome.toLowerCase().includes(value.nome.toLowerCase()));
              } else {
                return this.clientes.filter(c => c.nome.toLowerCase().includes(value.toLowerCase()));
              }
            })
          );
        //console.log(this.filteredCliente)
      });

      await this.plantasService.getPlantas().then(planta => {
        this.plantas = planta;
        this.filteredPlantas = this.editOperacaoForm.controls.plantaBreve.valueChanges
          .pipe(
            startWith(''),
            filter(value => value !== null),
            map(value => {
              if (value.breve) {
                return this.plantas.filter(c => c.breve.toLowerCase().includes(value.breve.toLowerCase()));
              } else {
                return this.plantas.filter(c => c.breve.toLowerCase().includes(value.toLowerCase()));
              }
            })
          );
      });

      await this.terminaisService.getTerminais().then(terminais => {
        this.terminais = terminais;
        // debugger
        this.initFilteredTerminais();
      });
      await this.bercosService.getBercos().then(bercos => {
        this.bercos = bercos;
        this.initFilteredBercos();
      });

      await this.operacoesService.getTiposPosicao().then(tiposPosicao => {
        this.tiposPosicao = tiposPosicao;
      });

      await this.operacoesService.getTiposAtracao().then(tiposAtracao => {
        this.tiposAtracao = tiposAtracao;
      });


      this.formUtils.validateFields();

      let operacao = this.operacao
      // this.quantLotes = operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados).length
      // if (this.quantLotes <= 2) {
      //   this.quantClick = 0
      // }
      // else {
      //   if (this.quantLotes == 3) {
      //     this.quantClick++;
      //   }
      //   else {
      //     this.quantClick = 2
      //   }
      // }
      console.log(operacao);
      
      const pais = this.countryService.getCountrys().filter(filtered => filtered.sigla2.indexOf(operacao.codigoPais)  > -1 )[0].nome;

      this.editOperacaoForm.setValue({
        id_operacao: operacao.id_operacao,
        numero: operacao.numero,
        id_planta: this.plantas.filter(el => el.descricao === operacao.planta)[0].id_planta,
        plantaBreve: this.plantas.filter(el => el.descricao === operacao.planta)[0],
        id_terminal: this.terminais.filter(el => el.breve === operacao.terminal)[0].id_terminal,
        terminalBreve: this.terminais.filter(el => el.breve === operacao.terminal)[0],
        id_berco: operacao.berco ? this.bercos.filter(el => el.breve === operacao.berco)[0].id_berco : 0,
        bercoBreve: operacao.berco ? this.bercos.filter(el => el.breve === operacao.berco)[0] : "",
        id_navioOrigem: this.navios.filter(el => el.descricao === operacao.origemNome)[0].id_navio,
        navioOrigemBreve: this.navios.filter(el => el.descricao === operacao.origemNome)[0],
        id_navioDestino: this.navios.filter(el => el.descricao === operacao.destinoNome)[0].id_navio,
        navioDestinoBreve: this.navios.filter(el => el.descricao === operacao.destinoNome)[0],
        nomeCliente: this.clientes.filter(el => el.nome === operacao.nomeCliente)[0],
        id_cliente: this.clientes.filter(el => el.nome === operacao.nomeCliente)[0].id_cliente,
        id_posicaoBerco: operacao.id_posicaoBerco,
        id_posicaoBercoDestino: operacao.id_posicaoBercoDestino,
        id_bordoAtracacao: operacao.id_bordoAtracacao,
        id_bordoAtracacaoDestino: operacao.id_bordoAtracacaoDestino,
        // caladoEntrada: `${operacao.caladoEntrada.toString().length > 3 ? operacao.caladoEntrada.toString().substring(0, 2) : "0" + operacao.caladoEntrada.toString().substring(0, 1)},${operacao.caladoEntrada.toString().length > 3 ? operacao.caladoEntrada.toString().substring(2, operacao.caladoEntrada.toString().length).padEnd(2, '0') : operacao.caladoEntrada.toString().substring(1, operacao.caladoEntrada.toString().length).padEnd(1, '0')}`,

        // caladoEntradaDestino: `${operacao.caladoEntradaDestino.toString().length > 3 ? operacao.caladoEntradaDestino.toString().substring(0, 2) : "0" + operacao.caladoEntradaDestino.toString().substring(0, 1)},${operacao.caladoEntradaDestino.toString().length > 3 ? operacao.caladoEntradaDestino.toString().substring(2, operacao.caladoEntradaDestino.toString().length).padEnd(2, '0') : operacao.caladoEntradaDestino.toString().substring(1, operacao.caladoEntradaDestino.toString().length).padEnd(1, '0')}`,
        // caladoEntrada: operacao.caladoEntrada.toString() != '0' ? `${operacao.caladoEntrada.toString().length > 3 ? operacao.caladoEntrada.toString().substring(0, 2) : "0" + operacao.caladoEntrada.toString().substring(0, 1)},${operacao.caladoEntrada.toString().length > 3 ? operacao.caladoEntrada.toString().substring(2, operacao.caladoEntrada.toString().length).padEnd(2, '0') : operacao.caladoEntrada.toString().substring(1, operacao.caladoEntrada.toString().length).padEnd(1, '0')}` : "00,00",
        // caladoEntradaDestino: operacao.caladoEntradaDestino.toString() != '0' ? `${operacao.caladoEntradaDestino.toString().length > 3 ? operacao.caladoEntradaDestino.toString().substring(0, 2) : "0" + operacao.caladoEntradaDestino.toString().substring(0, 1)},${operacao.caladoEntradaDestino.toString().length > 3 ? operacao.caladoEntradaDestino.toString().substring(2, operacao.caladoEntradaDestino.toString().length).padEnd(2, '0') : operacao.caladoEntradaDestino.toString().substring(1, operacao.caladoEntradaDestino.toString().length).padEnd(1, '0')}` : "00,00",

        caladoEntrada: operacao.caladoEntrada?operacao.caladoEntrada.toLocaleString('pt-BR',{minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2}):'00,00',
        caladoEntradaDestino: operacao.caladoEntradaDestino?operacao.caladoEntradaDestino.toLocaleString('pt-BR',{minimumIntegerDigits: 2, minimumFractionDigits: 2, maximumFractionDigits: 2}):'00,00',


        viagemOrigem: operacao.viagemOrigem,
        viagemDestino: operacao.viagemDestino,

        escalaOrigem: operacao.escalaOrigem,
        escalaDestino: operacao.escalaDestino,

        id_loteA: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[0] ? JSON.parse(operacao.lotesVinculados)[0].id_operacaoLote : 0,
        NFloteA: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[0] ? JSON.parse(operacao.lotesVinculados)[0].NF : 0,
        numeroLoteA: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[0] ? JSON.parse(operacao.lotesVinculados)[0].numero : 0,
        volumeM3A: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[0] ? JSON.parse(operacao.lotesVinculados)[0].volumeM3 : 0,
        OSGloteA: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[0] ? JSON.parse(operacao.lotesVinculados)[0].OSG : 0,
        procedenciaLoteA: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[0] ? JSON.parse(operacao.lotesVinculados)[0].identificacao : 0,

        id_loteB: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[1] ? JSON.parse(operacao.lotesVinculados)[1].id_operacaoLote : 0,
        NFloteB: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[1] ? JSON.parse(operacao.lotesVinculados)[1].NF : 0,
        numeroLoteB: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[1] ? JSON.parse(operacao.lotesVinculados)[1].numero : 0,
        volumeM3B: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[1] ? JSON.parse(operacao.lotesVinculados)[1].volumeM3 : 0,
        OSGloteB: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[1] ? JSON.parse(operacao.lotesVinculados)[1].OSG : 0,
        procedenciaLoteB: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[1] ? JSON.parse(operacao.lotesVinculados)[1].identificacao : 0,

        id_loteC: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[2] ? JSON.parse(operacao.lotesVinculados)[2].id_operacaoLote : 0,
        NFloteC: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[2] ? JSON.parse(operacao.lotesVinculados)[2].NF : 0,
        numeroLoteC: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[2] ? JSON.parse(operacao.lotesVinculados)[2].numero : 0,
        volumeM3C: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[2] ? JSON.parse(operacao.lotesVinculados)[2].volumeM3 : 0,
        OSGloteC: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[2] ? JSON.parse(operacao.lotesVinculados)[2].OSG : 0,
        procedenciaLoteC: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[2] ? JSON.parse(operacao.lotesVinculados)[2].identificacao : 0,

        id_loteD: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[3] ? JSON.parse(operacao.lotesVinculados)[3].id_operacaoLote : 0,
        NFloteD: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[3] ? JSON.parse(operacao.lotesVinculados)[3].NF : 0,
        numeroLoteD: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[3] ? JSON.parse(operacao.lotesVinculados)[3].numero : 0,
        volumeM3D: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[3] ? JSON.parse(operacao.lotesVinculados)[3].volumeM3 : 0,
        OSGloteD: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[3] ? JSON.parse(operacao.lotesVinculados)[3].OSG : 0,
        procedenciaLoteD: operacao.lotesVinculados && JSON.parse(operacao.lotesVinculados)[3] ? JSON.parse(operacao.lotesVinculados)[3].identificacao : 0,
      
        paisDestino:  pais?pais:''
      })

    }

  }

  // applyFilter(filterValue: string) {
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }

  saveOperacao() {

    this.operacaoRequest.id_operacao = this.editOperacaoForm.value.id_operacao;

    this.operacaoRequest.id_cliente = this.operacao.id_cliente;
    this.operacaoRequest.numero = this.operacao.numero;

    this.operacaoRequest.id_terminal = this.editOperacaoForm.value.id_terminal ? this.editOperacaoForm.value.id_terminal : 0;
    this.operacaoRequest.id_planta = this.editOperacaoForm.value.id_planta ? this.editOperacaoForm.value.id_planta : 0;
    this.operacaoRequest.id_berco = this.editOperacaoForm.value.id_berco ? this.editOperacaoForm.value.id_berco : 0;
    this.operacaoRequest.id_origem = this.editOperacaoForm.value.id_navioOrigem ? this.editOperacaoForm.value.id_navioOrigem : 0;
    this.operacaoRequest.id_destino = this.editOperacaoForm.value.id_navioDestino ? this.editOperacaoForm.value.id_navioDestino : 0;

    this.operacaoRequest.caladoEntradaDestino = this.editOperacaoForm.value.caladoEntradaDestino;
    this.operacaoRequest.caladoEntrada = this.editOperacaoForm.value.caladoEntrada;

    this.operacaoRequest.viagemOrigem = this.editOperacaoForm.value.viagemOrigem;
    this.operacaoRequest.viagemDestino = this.editOperacaoForm.value.viagemDestino;

    this.operacaoRequest.escalaOrigem = this.editOperacaoForm.value.escalaOrigem;
    this.operacaoRequest.escalaDestino = this.editOperacaoForm.value.escalaDestino;

    this.operacaoRequest.id_posicaoBerco = this.editOperacaoForm.value.id_posicaoBerco;
    this.operacaoRequest.id_posicaoBercoDestino = this.editOperacaoForm.value.id_posicaoBercoDestino;
    this.operacaoRequest.id_bordoAtracacao = this.editOperacaoForm.value.id_bordoAtracacao;
    this.operacaoRequest.id_bordoAtracacaoDestino = this.editOperacaoForm.value.id_bordoAtracacaoDestino;

    this.operacaoRequest.id_posicaoBerco = this.editOperacaoForm.value.id_posicaoBerco;
    this.operacaoRequest.id_bordoAtracacao = this.editOperacaoForm.value.id_bordoAtracacao;
    this.operacaoRequest.caladoEntrada = this.editOperacaoForm.value.caladoEntrada;
    this.operacaoRequest.caladoEntradaDestino = this.editOperacaoForm.value.caladoEntradaDestino;
    this.operacaoRequest.id_posicaoBercoDestino = this.editOperacaoForm.value.id_posicaoBercoDestino;
    this.operacaoRequest.id_bordoAtracacaoDestino = this.editOperacaoForm.value.id_bordoAtracacaoDestino;

    this.operacaoRequest.codigoPais = this.paisSigla;
    
    if (this.editOperacaoForm.value.NFloteA.length > 0 ||
      this.editOperacaoForm.value.numeroLoteA.length > 0 ||
      this.editOperacaoForm.value.volumeM3A.length > 0 ||
      this.editOperacaoForm.value.OSGloteA.length > 0 ||
      this.editOperacaoForm.value.procedenciaLoteA.length > 0) {

      this.operacaoRequest.id_loteA = this.editOperacaoForm.value.id_loteA;
      this.operacaoRequest.NFloteA = this.editOperacaoForm.value.NFloteA;
      this.operacaoRequest.numeroLoteA = this.editOperacaoForm.value.numeroLoteA;
      this.operacaoRequest.volumeM3A = this.editOperacaoForm.value.volumeM3A;
      this.operacaoRequest.OSGloteA = this.editOperacaoForm.value.OSGloteA;
      this.operacaoRequest.procedenciaLoteA = this.editOperacaoForm.value.procedenciaLoteA;
    }
    if (this.editOperacaoForm.value.NFloteB.length > 0 ||
      this.editOperacaoForm.value.numeroLoteB.length > 0 ||
      this.editOperacaoForm.value.volumeM3B.length > 0 ||
      this.editOperacaoForm.value.OSGloteB.length > 0 ||
      this.editOperacaoForm.value.procedenciaLoteB.length > 0) {

      this.operacaoRequest.id_loteB = this.editOperacaoForm.value.id_loteB;
      this.operacaoRequest.NFloteB = this.editOperacaoForm.value.NFloteB;
      this.operacaoRequest.numeroLoteB = this.editOperacaoForm.value.numeroLoteB;
      this.operacaoRequest.volumeM3B = this.editOperacaoForm.value.volumeM3B;
      this.operacaoRequest.OSGloteB = this.editOperacaoForm.value.OSGloteB;
      this.operacaoRequest.procedenciaLoteB = this.editOperacaoForm.value.procedenciaLoteB;
    }
    if (this.editOperacaoForm.value.NFloteC.length > 0 ||
      this.editOperacaoForm.value.numeroLoteC.length > 0 ||
      this.editOperacaoForm.value.volumeM3C.length > 0 ||
      this.editOperacaoForm.value.OSGloteC.length > 0 ||
      this.editOperacaoForm.value.procedenciaLoteC.length > 0) {

      this.operacaoRequest.id_loteC = this.editOperacaoForm.value.id_loteC;
      this.operacaoRequest.NFloteC = this.editOperacaoForm.value.NFloteC;
      this.operacaoRequest.numeroLoteC = this.editOperacaoForm.value.numeroLoteC;
      this.operacaoRequest.volumeM3C = this.editOperacaoForm.value.volumeM3C;
      this.operacaoRequest.OSGloteC = this.editOperacaoForm.value.OSGloteC;
      this.operacaoRequest.procedenciaLoteC = this.editOperacaoForm.value.procedenciaLoteC;
    }
    if (this.editOperacaoForm.value.NFloteD.length > 0 ||
      this.editOperacaoForm.value.numeroLoteD.length > 0 ||
      this.editOperacaoForm.value.volumeM3D.length > 0 ||
      this.editOperacaoForm.value.OSGloteD.length > 0 ||
      this.editOperacaoForm.value.procedenciaLoteD.length > 0) {

      this.operacaoRequest.id_loteD = this.editOperacaoForm.value.id_loteD;
      this.operacaoRequest.NFloteD = this.editOperacaoForm.value.NFloteD;
      this.operacaoRequest.numeroLoteD = this.editOperacaoForm.value.numeroLoteD;
      this.operacaoRequest.volumeM3D = this.editOperacaoForm.value.volumeM3D;
      this.operacaoRequest.OSGloteD = this.editOperacaoForm.value.OSGloteD;
      this.operacaoRequest.procedenciaLoteD = this.editOperacaoForm.value.procedenciaLoteD;
    }
  }

  cancelAddEdit() {
    this.quantClick = 0;
    this.editOperacaoForm.setValue({
      id_operacao: 0,
      numero: '',
      nomeCliente: '',
      id_cliente: '',
      terminalBreve: '',
      id_terminal: '',
      bercoBreve: '',
      id_berco: '',
      plantaBreve: '',
      id_planta: '',
      navioOrigemBreve: '',
      navioDestinoBreve: '',
      id_navioOrigem: '',
      id_navioDestino: '',
      id_posicaoBerco: '',
      id_posicaoBercoDestino: '',
      id_bordoAtracacao: '',
      id_bordoAtracacaoDestino: '',
      caladoEntrada: '',
      caladoEntradaDestino: '',
      id_loteA: '',
      id_loteB: '',
      id_loteC: '',
      id_loteD: '',
      numeroLoteA: '',
      numeroLoteB: '',
      numeroLoteC: '',
      numeroLoteD: '',
      procedenciaLoteA: '',
      procedenciaLoteB: '',
      procedenciaLoteC: '',
      procedenciaLoteD: '',
      NFloteA: '',
      NFloteB: '',
      NFloteC: '',
      NFloteD: '',
      OSGloteA: '',
      OSGloteB: '',
      OSGloteC: '',
      OSGloteD: '',
      volumeM3A: '',
      volumeM3B: '',
      volumeM3C: '',
      volumeM3D: '',
    })
  }

  plantasAutoCompleteDisplay = (planta?: Planta): string | undefined => {
    if (planta) {
      this.editOperacaoForm.patchValue({
        "id_planta": planta.id_planta
      })
      return planta.breve
    }
    else {
      return undefined;
    }
  }

  terminaisAutoCompleteDisplay = (terminal?: Terminal): string | undefined => {

    if (terminal) {
      this.editOperacaoForm.patchValue({
        "id_terminal": terminal.id_terminal
      })

      return terminal.breve
    }
    else {
      return undefined;
    }
  }

  bercosAutoCompleteDisplay = (berco?: Berco): string | undefined => {
    if (berco) {

      this.editOperacaoForm.patchValue({
        "id_berco": berco.id_berco
      })

      return berco.breve
    }
    else {
      return undefined;
    }
  }

  navioOrigemAutoCompleteDisplay = (navio?: Navio): string | undefined => {
    if (navio) {

      this.editOperacaoForm.patchValue({
        "id_navioOrigem": navio.id_navio
      })

      return navio.descricao
    }
    else {
      return undefined;
    }
  }

  navioDestinoAutoCompleteDisplay = (navio?: Navio): string | undefined => {
    if (navio) {

      this.editOperacaoForm.patchValue({
        "id_navioDestino": navio.id_navio
      })

      return navio.descricao
    }
    else {
      return undefined;
    }
  }

  nomeCompletoDisplay = (cliente?: Cliente): string | undefined => {
    if (cliente) {
      this.editOperacaoForm.patchValue({
        "id_cliente": cliente.id_cliente
      })
      return cliente.nome
    }
    else {
      return undefined;
    }
  }

  initFilteredBercos(terminalBreve: string = null) {

    this.filteredBercos = this.editOperacaoForm.controls.bercoBreve.valueChanges
      .pipe(
        startWith(''),
        filter(value => value !== null),
        map(value => {
          if (value.breve) {
            if (terminalBreve) return this.bercos.filter(el => el.terminal === terminalBreve).filter(c => c.breve.toLowerCase().includes(value.breve.toLowerCase()));
            else return this.bercos.filter(c => c.breve.toLowerCase().includes(value.breve.toLowerCase()));
          } else {
            if (terminalBreve) return this.bercos.filter(el => el.terminal === terminalBreve).filter(c => c.breve.toLowerCase().includes(value.toLowerCase()));
            else return this.bercos.filter(c => c.breve.toLowerCase().includes(value.toLowerCase()));
          }
        })
      );
  }

  initFilteredTerminais(plantaBreve: string = null) {
    this.filteredTerminais = this.editOperacaoForm.controls.terminalBreve.valueChanges
      .pipe(
        startWith(''),
        filter(value => value !== null),
        map(value => {
          if (value.breve) {
            if (plantaBreve) return this.terminais.filter(el => el.plantaDescricao === plantaBreve).filter(c => c.breve.toLowerCase().includes(value.breve.toLowerCase()));
            else return this.terminais.filter(c => c.breve.toLowerCase().includes(value.breve.toLowerCase()));
          } else {
            if (plantaBreve) return this.terminais.filter(el => el.plantaDescricao === plantaBreve).filter(c => c.breve.toLowerCase().includes(value.toLowerCase()));
            else return this.terminais.filter(c => c.breve.toLowerCase().includes(value.toLowerCase()));
          }
        })
      )
  }

  addLote() {
    if (this.quantClick < 2) {
      this.quantClick++
    }
    else {
      this.quantClick = this.quantClick;
    }
  }

  validateValue(value, defaultValue: any = 0) {
    return value ? value : defaultValue;
  }

  padEnd(event) {
    // let value = event.target.value.replace(',', '');
    // value = `${value.length > 3 ? value.substring(0, 2) : value.substring(0, 1)},${value.length > 3 ? value.substring(2, value.length).padEnd(2, '0') : value.substring(1, value.length).padEnd(1, '0')}`
    // event.target.value = value
  }

  dotClass(evento) {
    if (evento.dataCancelamento) return "opErro";
    if (evento.dataInicio && evento.dataFim) return "opOk";
    if (evento.dataInicio && !evento.dataFim) return "opExecucao";
    if (!evento.dataInicio && !evento.dataFim) return "opPausa";
  }

  transformDate(date: any, defaultValue: any = null) {
    if (date) {
      let newDate = moment(date).format(date)
      return newDate
    }
    else {
      return defaultValue
    }
  }

  async atualizarDataEvento() {

    let data1 = new Date(
      parseInt(this.newDate.split('/')[2]),
      parseInt(this.newDate.split('/')[1]) - 1,
      parseInt(this.newDate.split('/')[0]),
      parseInt(this.hora.split(':')[0]),
      parseInt(this.hora.split(':')[1]),
    );

    // let data2 = new Date(this.transformDate(this.proximoEvento.dataInicio))

    let ano = moment(data1).format("YYYY");
    let mes = moment(data1).format("MM");
    let dia = moment(data1).format("DD");

    this.newDate = `${ano}-${mes}-${dia} ${this.hora}`
    this.dado = {
      newDate: this.newDate,
      descricao: this.descricao,
      id_operacaoEvento: this.evento.id_operacaoEvento,
      id_operacao: this.operacao.id_operacao,
      tipo: this.tipo
    }
    await this.operacaoEventoService.atualizaDataEvento(this.dado)
      .then(res => {
        this.alert.overflowIconSuccessAlert({
          title: "Sucesso",
          text: "Horário atualizado!"
        });
      })
      .catch(err => {
        //console.log(err)
        this.alert.overflowIconErrorAlert({
          title: "ALERT.ATENCAO",
          text: err.error.message
        })
      })
  }

  async atualizarDataSubItens() {

    let data1 = new Date(
      parseInt(this.newDate.split('/')[2]),
      parseInt(this.newDate.split('/')[1]) - 1,
      parseInt(this.newDate.split('/')[0]),
      parseInt(this.hora.split(':')[0]),
      parseInt(this.hora.split(':')[1]),
    );

    let ano = moment(data1).format("YYYY");
    let mes = moment(data1).format("MM");
    let dia = moment(data1).format("DD");

    this.newDate = `${ano}-${mes}-${dia} ${this.hora}`
    this.dado = {
      newDate: this.newDate,
      descricao: this.descricao,
      id_operacaoEventoComplemento: this.evento.id_operacaoEventoComplemento,
      id_operacaoEvento: this.evento.id_operacaoEvento,
      id_operacao: this.operacao.id_operacao,
      tipo: this.tipo
    }
    //console.log(this.evento)
    //console.log(this.dado)
    await this.operacaoEventoService.atualizarDataSubItens(this.dado)
      .then(res => {
        this.alert.overflowIconSuccessAlert({
          title: "Sucesso",
          text: "Horário atualizado!"
        });
      })
      .catch(err => {
        this.alert.overflowIconErrorAlert({
          title: "ALERT.ATENCAO",
          text: err.error.message
        })
      })
  }

  async atualizarDataVazao(item) {
    this.editableId = true;
    this.inputDataObj = {
      ...item,
      hora: item.hora,
      volumeM3: item.volumeM3,
      pressao: item.pressao,
      pressaoManifold: item.pressaoManifold,
      destinoVolumeM3: item.destinoVolumeM3,
      destinoPressao: item.destinoPressao,
      descricao: '',
    }
  }

  async saveDataVazao() {
    this.dado = {
      ...this.inputDataObj,
      descricao: this.descricao,
      id_operacao: this.operacao.id_operacao,
      tipo: this.tipo,
      id_lote: this.id_lote,
    }
    await this.operacaoVazaoService.editOperacaoVazao(this.dado)
      .then(res => {
        this.alert.overflowIconSuccessAlert({
          title: "Sucesso",
          text: "Horário atualizado!"
        });
      })
      .catch(err => {
        this.alert.overflowIconErrorAlert({
          title: "ALERT.ATENCAO",
          text: err.error.message
        })
      })
  }

  async editVazao(item) {

    //console.log(item);

    await this.operacaoVazaoService.editOperacaoVazao(item)
      .then(res => {
        //console.log(res)
        // this.alert.overflowIconSuccessAlert({
        //   title: "Sucesso",
        //   text: "Horário atualizado!"
        // });
      })
      .catch(err => {
        //console.log(err)
        // this.alert.overflowIconErrorAlert({
        //   title: "ALERT.ATENCAO",
        //   text: err.error.message
        // });
      })
  }

  async addVazao(item: any) {

    let opVazao = new AddOperacaoVazao();
    opVazao.id_operacao = this.operacao.id_operacao;
    opVazao.volumeM3 = item.volumeM3;
    opVazao.hora = item.hora;
    opVazao.pressao = item.pressao;
    opVazao.pressaoManifold = item.pressaoManifold;
    opVazao.destinoVolumeM3 = item.destinoVolumeM3;
    opVazao.destinoPressao = item.destinoPressao;
    opVazao.tipo = this.tipo;
    opVazao.id_loteSelecionado = this.id_lote;


    await this.operacaoVazaoService.addOperacaoVazao(opVazao)
      .then(res => {
        //console.log(res)
        this.alert.overflowIconSuccessAlert({
          title: "Sucesso",
          text: "Leitura incluída!"
        });
        this.dialogRef.close();
      }).catch(err => {
        //console.log(err)
        this.alert.overflowIconErrorAlert({
          title: "ALERT.ATENCAO",
          text: err.error.message
        });
      });

  }

  async apagarVazao(item) {

    //console.log(item);

    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        iconType: 'success',
        iconPosition: 'overflow',
        title: "ALERT.ATENCAO",
        text: "ALERT.VAZAO.MSG_EXCLUIR",
        options: true
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.operacaoVazaoService.deleteOperacaoVazao(item)
          .then(res => {
            this.dialog.open(AlertTemplateComponent, {
              data: {
                iconType: 'success',
                iconPosition: 'overflow',
                title: 'ALERT.SUCESSO',
                text: 'ALERT.VAZAO.MSG_EXCLUIDO',
                button: 'Ok'
              },
              autoFocus: false
            });
            this.dialogRef.close();
          }).catch(err => {
            //console.log(err)
          });
      }
    });

  }

  async addObservacao(item) {
    //console.log(item);

    const dialogRef = this.dialog.open(AlertTemplateComponent, {
      data: {
        title: "ALERT.VAZAO.OBSERVACAO",
        input: true,
        dataType: "text-expand",
        inputData: item.obs
      },
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        item.obs = result;
        this.operacaoVazaoService.addOperacaoObs(item).then(res => {
          //console.log(res)
        }).catch(err => {
          //console.log(err)
        });
        //console.log(result);
      }
    });
  }

  validaStrippingTime(){
    if(this.stripping.begin && this.stripping.begin.length<5) return false
    if(this.stripping.end && this.stripping.end.length<5) return false
    return (this.stripping.begin!=this.strippingTimeNow.begin || this.stripping.end!=this.strippingTimeNow.end)
  }

  saveStrippingTime(){
    this.operacaoVazaoService.teste(this.id_operacao,this.id_lote,this.stripping)
      .then(res => {
        // console.log(res);
        this.strippingTimeNow = {...this.stripping}
      })
      .catch(err => {
        this.stripping = {...this.strippingTimeNow};        
        console.log(err)
      })
  }

  getStrippingTime(date){
    if(date){
      const time = new Date(date);
      return `${time.getHours()+3}:${time.getMinutes()}`
    }
    else {
      return ''
    }
  }

  countryFilter (event) {
    const query = event.target.value.toLowerCase();
    this.filterdCountrys = this.countryService.getCountrys().filter(filtered => filtered.nome.toLocaleLowerCase().indexOf(query)  > -1 );
  }

  verifyCountry(event) {
    const query = event.target.value.toLowerCase();
    const value = this.countryService.getCountrys().filter(filtered => filtered.nome.toLocaleLowerCase().indexOf(query)  > -1 );
    if(value.length == 1){
      this.paisSigla = value[0].sigla2;
    }
    else if(query.trim() === 'estados unidos' && value.length >= 4) {
      this.paisSigla = this.countryService.getCountrys()[65].sigla2;
    }
    else if(query.trim() === 'guiana' && value.length >= 2) {
      this.paisSigla = this.countryService.getCountrys()[85].sigla2;
    }
    else if(query.trim() === 'guiné' && value.length >= 4) {
      this.paisSigla = this.countryService.getCountrys()[87].sigla2;
    }
    else if(query.trim() === 'noruega' && value.length >= 2) {
      this.paisSigla = this.countryService.getCountrys()[175].sigla2;
    }
    else if(query.trim() === 'geórgia' && value.length >= 2) {
      this.paisSigla = this.countryService.getCountrys()[75].sigla2;
    }
    else if(query.trim() === 'austrália' && value.length >= 2) {
      this.paisSigla = this.countryService.getCountrys()[15].sigla2;
    }
    else if(query.trim() === 'congo' && value.length >= 2) {
      this.paisSigla = this.countryService.getCountrys()[47].sigla2;
    }
    else if(query.trim() === 'dominica' && value.length >= 2) {
      this.paisSigla = this.countryService.getCountrys()[56].sigla2;
    }
    else if(query.trim() === 'frança' && value.length >= 2) {
      this.paisSigla = this.countryService.getCountrys()[71].sigla2;
    }
    else {
      this.paisSigla = ''
      if(this.editableId){
        this.editOperacaoForm.controls['paisDestino'].setErrors({'invalid_country': true});
      }
      else {
        this.novaOperacaoForm.controls['paisDestino'].setErrors({'invalid_country': true});
      }
    }
  }

}

class AddOperacaoVazao {
  public id_empresa: number = 0;
  public id_usuario: number = 0;
  public id_operacao: number = 0;
  public volumeTonelada: number = 0;
  public volumeBarril: number = 0;
  public volumeM3: number = 0;
  public hora: string = "";
  public tipo: string = "";
  public pressao: number = 0;
  public pressaoManifold: number = 0;
  public destinoPressao: number = 0;
  public destinoVolumeM3: number = 0;
  public id_loteSelecionado: number = 0;

}